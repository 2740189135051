/* eslint-disable max-len */
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Layout from '../layouts/index';
import SEO from '../components/seo';
import '../styles/Components/about.scss';
import StarWrapper from '../components/Common/StarWrapper';
import Rhys from "../images/ya_boi.jpg";
import Community from "../images/rhys_and_matt.jpg";

const About = () => {
  if (typeof AOS.init === 'function') {
    AOS.init({
      once: true,
    });
  }

  return(
    <Layout>
      <SEO title="About Us" />
      <div>
        <StarWrapper />
        <section className="top-container">
          <h1>About Us</h1>
        </section>
        <section className="card-list">
          <div className="card-container-left" data-aos="fade-right" data-aos-duration="2000">
              <div className="text-container">
                <h1 class="text-left">Our Purpose</h1>
                <h2 class="text-left">We believe community can make change</h2>
                <p>
                  We created Reach to help world-changing brands connect 
                  and understand how their communities operate on social media.
                  <br></br><br></br>
                  We pride ourselves on providing the best-in-class technology
                  so your brand can unlock the full potential behind your community.
                </p>
              </div>
              <div className="img-container">
                <img src={Community}></img>
              </div>
          </div>
          <div className="card-container-right" data-aos="fade-left" data-aos-duration="2000">
            <div className="img-container">
              <img className="left" src={Rhys}></img>
            </div>
            <div className="text-container">
              <h1 class="text-left">Our Story</h1>
              <h2 class="text-left">This is just the beginning</h2>
              <p>
                In May 2019, <a href="https://rhyslawson.com">Rhys Lawson</a> started persuing the idea for Reach
                due to his interest in sociology and his passion for automated systems. Since then 
                he has been working with a small team of talented individuals to build an easy to use
                platform that not only provides in-depth community insights, but also automatically responds
                to comments or concerns using a naturally responding conversational AI.
                <br></br><br></br>
                Fast forward to today, we have built a platform that accomplishes much more than 
                what Rhys had initally invisioned, but there's still more work to do. We have been working 
                alongside brands to make this platform into something that will not only show the true 
                potential of communities, but also will truly change the way we look at online communities.
              </p>
            </div>
          </div>
          <div className="card-container-center" data-aos="fade-up" data-aos-duration="2000">
            <div className="text-container text-center">
              <h1>Our Customers</h1>
              <h2>Succeeding, together</h2>
              <p>
                We’re focused on helping our customers—large and small—better understand their 
                online community, business, and customer relationships so they can succeed with social.
              </p>
            </div>
            <div className="brand-container text-center">
              {/* <img src={}></img>
              <img src={}></img>
              <img src={}></img>
              <img src={}></img> */}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default About;
